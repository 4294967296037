<template>
  <div v-if="order" class="purchase-order-detail position-relative">
    <div v-if="!pageLoad" class="page-loader">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>

    <div class="page-title d-flex pt-2 pb-3 justify-content-between">
      <h1 class="h4 m-0">
        Заказ на поставку № {{ order.number }} от
        {{ order.order_date | parseDate }}
      </h1>

      <div class="d-flex align-items-center">
        <template
          v-if="
            order.customer_status === 'Черновик' &&
            order.provider_status === 'Черновик' &&
            isContractor
          "
        >
          <BButton class="ml-3" variant="danger" v-b-modal.confirm-delete
            >Удалить
          </BButton>
          <BButton
            class="ml-3"
            variant="outline-primary"
            :to="`${$route.fullPath}/edit`"
            >Редактировать
          </BButton>
        </template>

        <BButton class="ml-3" variant="warning"
          @click="onExport( 'order', orderId )"
        >Экспорт </BButton>

        <BButton
          v-if="
            order.customer_status === 'Черновик' &&
            order.provider_status === 'Черновик'
          "
          class="ml-3"
          variant="primary"
          @click="approveOrderFromContractor"
          >Отправить на согласование
        </BButton>

        <template
          v-if="
            ( order.customer_status === 'Согласовано' ) &&
            ( order.provider_status === 'На рассмотрении' ) &&
            isProvider
          "
        >
          <BButton
            class="ml-3"
            variant="outline-danger"
            v-b-modal.reject-order
            >Отказать
          </BButton>
          <BButton
            class="ml-3"
            variant="primary"
            v-b-modal.approve-order
            >Согласовать
          </BButton>
        </template>

        <BButton
          v-if="canClose"
          class="ml-3"
          variant="danger"
          v-b-modal.close-order
          >Отправить запрос на закрытие заказа
        </BButton>

        <span
          v-if="
            isContractor &&
            order.contractor_require_closure &&
            !order.provider_closing_confirmation &&
            !order.contractor_closing_confirmation
          "
          class="text-danger ml-3"
          >Отправлен запрос на закрытие заказа
        </span>

        <span
          v-if="
            !isContractor &&
            order.provider_require_closure &&
            !order.provider_closing_confirmation &&
            !order.contractor_closing_confirmation
          "
          class="text-danger ml-3"
          >Отправлен запрос на закрытие заказа
        </span>

        <BButton
          v-if="
            isContractor &&
            order.provider_require_closure &&
            !order.provider_closing_confirmation &&
            !order.contractor_closing_confirmation
          "
          class="ml-3"
          variant="danger"
          v-b-modal.confirm-close-order
          >Подтвердить закрытие заказа
        </BButton>

        <BButton
          v-if="
            !isContractor &&
            order.contractor_require_closure &&
            !order.provider_closing_confirmation &&
            !order.contractor_closing_confirmation
          "
          class="ml-3"
          variant="danger"
          v-b-modal.confirm-close-order
          >Подтвердить закрытие заказа
        </BButton>
      </div>
    </div>

    <b-tabs v-model="tabIndex" card>
      <b-tab title="Информация">
        <OrderInformation />
      </b-tab>

      <b-tab title="Позиции МТР">
        <div class="d-flex align-items-center justify-content-between">
          <TableFilter
            :columns="mtrFieldsFiltered"
            uniqueKey="table-2"
            @update-selected-columns="mtrColumns = $event"
            @search="filter = $event"
            @open-filter-modal="
              $root.$emit('bv::toggle::collapse', 'mtr-positions-filter')
            "
            @clear="clearMtrFilterData"
          />

          <div
            v-if="
              refusePositionList.length > 0 &&
              isProvider &&
              order.provider_status === 'На рассмотрении'
            "
            class="d-flex align-items-center"
          >
            <span
              >{{
                $options.filters.declensionNumbers(refusePositionList.length, [
                  "Выбрана",
                  "Выбрано",
                  "Выбраны",
                ])
              }}
              {{ refusePositionList.length }}
              {{
                $options.filters.declensionNumbers(refusePositionList.length, [
                  "позиция",
                  "позиции",
                  "позиций",
                ])
              }}</span
            >
            <BButton class="ml-3" variant="warning" v-b-modal.mtr-reject
              >Отказать и дать комментарий по выбранным позициям
            </BButton>
          </div>
        </div>

        <DefaultTable
          :data="positions"
          :fields="mtrFieldsFiltered"
          :filter="filter"
          :selectedColumns="refusePositionList"
          :withIterator="true"
          :withCommentModal="true"
          :canChangeData="['delivery_plan_time']"
          :loading="mtrLoading"
          :noLocalSorting="true"
          :withSpecifiedTotal="`Всего сумма по документу: ${
            order.positions_sum_amount_without_vat || 'Не указано'
          } рублей`"
          @sort-change="mtrSorting"
          @selected-rows="refusePositionList = $event"
          @refresh-table="loadPage()"
          @pagination-change="mtrPaginationChange"
          @per-page-change="mtrPerPageChange"
        />
      </b-tab>

      <b-tab title="Отчёт">
        <div v-if="report" class="d-flex mb-5">
          <div class="mr-5 flex-grow-1">
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Сумма заказа
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.amount_total }} ₽
              </div>
            </div>
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Сроки оплаты
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.payment_period }}
              </div>
            </div>
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Плановый срок по заказу
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.plan_deadline_date | parseDate }}
              </div>
            </div>
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Фактический срок исполнения по заказу
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.fact_deadline_date | parseDate }}
              </div>
            </div>
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Факт оплаты
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.payment_fact }} ₽
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="primary"
                  :style="{ cursor: 'pointer' }"
                  @click="$bvModal.show('pay-fact')"
                />
              </div>
            </div>
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Факт отгрузки
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.shipment_fact }} ₽
                <b-icon
                  icon="exclamation-circle-fill"
                  variant="primary"
                  :style="{ cursor: 'pointer' }"
                  @click="$bvModal.show('shipment-fact')"
                />
              </div>
            </div>
            <div class="d-flex table-bordered mb-1">
              <div
                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                style="width: 250px"
              >
                Сальдо
              </div>
              <div class="px-2 py-1 flex-grow-1">
                {{ report.top_report.balance }} ₽
              </div>
            </div>
          </div>

          <div>
            <BButton class="px-5" variant="outline-primary"
              >Выгрузить отчёт
            </BButton>
          </div>
        </div>

        <TableFilter
          :columns="reportMtrFields"
          uniqueKey="table-3"
          @update-selected-columns="reportMtrColumns = $event"
          @search="reportMtrFilter = $event"
          @open-filter-modal="
            $root.$emit('bv::toggle::collapse', 'report-mtr-filter')
          "
          @clear="clearReportFilterData"
        />

        <div class="report-mtr-table">
          <DefaultTable
            :data="report.bottom_report"
            :fields="reportMtrFieldsFiltered"
            :filter="reportMtrFilter"
            :loading="reportLoading"
            :noLocalSorting="true"
            emptyText="Нет МТР"
            @sort-change="reportSorting"
            @pagination-change="reportPaginationChange"
            @per-page-change="reportPerPageChange"
          />
        </div>
      </b-tab>
    </b-tabs>

    <FilterSidebar
      id="mtr-positions-filter"
      v-model="mtrFilterData"
      @apply="updatePositionsDataWithFilters(true)"
    />

    <FilterSidebar
      id="report-mtr-filter"
      v-model="reportFilterData"
      @apply="updateReportDataWithFilters(true)"
    />

    <b-modal id="pay-fact" centered title="Факт оплаты" :hide-footer="true">
      <div>
        <DefaultTable
          :data="paymentData"
          :fields="reportPayFactsFields"
          :pagination="false"
        />
      </div>
    </b-modal>

    <b-modal
      id="shipment-fact"
      centered
      title="Факт отгрузки"
      :hide-footer="true"
    >
      <div>
        <DefaultTable
          :data="shipmentData"
          :fields="reportShipmentFactsFields"
          :pagination="false"
        />
      </div>
    </b-modal>

    <b-modal id="mtr-reject" centered title="Добавить комментарий">
      <b-form-textarea
        v-model.trim="mtrRejectComment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="mtrReject"> Добавить </b-button>
      </template>
    </b-modal>

    <b-modal
      id="reject-order"
      centered
      :title="`Отказать в заказе ${order.number}`"
    >
      <b-form-textarea
        v-model.trim="rejectComment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="rejectOrder"> Отказать </b-button>
      </template>
    </b-modal>

    <b-modal
      id="approve-order"
      centered
      :title="`Согласовать заказ ${order.number}`"
    >
      <b-form-textarea
        v-model.trim="approveComment"
        placeholder="Текст комментария"
      />

      <template #modal-footer="{ hide }">
        <b-button variant="outline-primary" @click="hide()"> Отмена </b-button>
        <b-button variant="primary" @click="approveOrder">
          Согласовать
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="confirm-delete"
      :title="`Удаление заказа на поставку № ${
        order.number
      } от ${$options.filters.parseDate(order.order_date)}`"
      centered
    >
      <p class="m-0">Вы действительно хотите удалить данный заказ?</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="deleteOrder">Да </b-button>
      </template>
    </b-modal>

    <b-modal
      id="close-order"
      title="Отправить запрос на закрытие заказа"
      centered
    >
      <p class="m-0">
        Вы действительно хотите отправить запрос на закрытие заказа?
      </p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="сloseOrder()">Да </b-button>
      </template>
    </b-modal>

    <b-modal
      id="confirm-close-order"
      title="Подтверждение закрытия заказа"
      centered
    >
      <p class="m-0">Вы действительно хотите закрыть данный заказ?</p>
      <template #modal-footer="{ hide }">
        <b-button @click="hide()">Нет</b-button>
        <b-button variant="danger" @click="confirmCloseOrder">Да </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import TableFilter from "@/components/TableFilter";
import DefaultTable from "@/components/Tables/Default";
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import OrderInformation from "@/components/Order/OrderInformation";
import FilterSidebar from "@/components/FilterSidebar";
import axios from 'axios';

const currencyMask = createNumberMask({
  prefix: "",
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
  thousandsSeparatorSymbol: "",
});

function initialMtrFilterData() {
  return {
    status: "",
    mnemocode: "",
    nomenclature_name: "",
    price_without_vat: "",
    delivery_time: ["", ""],
    delivery_address: "",
  };
}

function initialReportFilterData() {
  return {
    mnemocode: "",
    nomenclature_name: "",
  };
}

export default {
  name: "PurchaseOrder",
  components: { OrderInformation, TableFilter, DefaultTable, FilterSidebar },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
    ...mapState({
      user: (state) => state.account.user,
      report: (state) => state.report.report,
      reportMtr: (state) => state.report.reportMtr,
      objects: (state) => state.referenceBooks.objects,
      organizations: (state) => state.referenceBooks.organizations,
      workAgreements: (state) => state.referenceBooks.workAgreements,
      contrAgents: (state) => state.referenceBooks.contrAgents,
    }),
    ...mapGetters({
      statuses: "getStatuses",
    }),
    isProvider() {
      return this.$store.getters.isProvider;
    },
    isContractor() {
      return this.$store.getters.isContractor;
    },
    mtrFieldsFiltered() {
      let res = [];
      this.mtrFields.forEach((item) => {
        if (item.key === "select") {
          if (this.isContractor) {
            return false;
          }
          if (this.order.provider_status !== "На рассмотрении") {
            return false;
          }
        }
        if (this.mtrColumns.filter((col) => col === item.label).length) {
          res.push(item);
        }
      });
      return res.length > 0 ? res : this.mtrFields;
    },
    reportMtrFieldsFiltered() {
      let res = [];
      if (this.reportMtrFields) {
        this.reportMtrFields.forEach((item) => {
          if (
            this.reportMtrColumns.filter((col) => col === item.label).length
          ) {
            res.push(item);
          }
        });
      }
      return res.length > 0 ? res : this.reportMtrFields;
    },

    canClose() {
      if (this.isContractor) {
        return (
          (this.order.provider_status === "Согласовано" ||
            this.order.provider_status === "Согласовано частично") &&
          (this.order.customer_status === "Согласовано" ||
            this.order.customer_status === "Согласовано частично")
        );
        // return (
        //   ((this.order.provider_status === "Согласовано" ||
        //     this.order.provider_status === "Согласовано частично") &&
        //     this.order.customer_status === "Согласовано" &&
        //     !this.order.contractor_require_closure &&
        //     !this.order.provider_require_closure) ||
        //   (this.order.customer_status === "Не согласовано" &&
        //     this.order.provider_status === "Не согласовано")
        // );
      }
      if (this.isProvider)
        return false
      return (
        (this.order.provider_status === "Согласовано" ||
          this.order.provider_status === "Согласовано частично") &&
        this.order.customer_status === "Согласовано" &&
        !this.order.contractor_require_closure &&
        !this.order.provider_require_closure
      );
    },

    order() {
      return this.$store.state.orders.currentOrder;
    },

    paymentData() {
      return {
        data: this.report.top_report.payment_fact_data,
      };
    },
    shipmentData() {
      return {
        data: this.report.top_report.shipment_fact_data,
      };
    },
    breadcrumbs() {
      return [
        { title: "Главная", link: "/" },
        { title: "Заказ ПО", link: "/" },
        { title: "Перечень заказов на поставку", link: "/purchase-orders" },
        {
          title: `Заказ на поставку № ${
            this.order.number
          } от ${this.$options.filters.parseDate(this.order.order_date)}`,
        },
      ];
    },
    positions() {
      const result = Array.from( this.order.positions );
      result.sort( ( a, b ) => ( ( a.id > b.id ) ? 1 : ( ( b.id > a.id ) ? -1 : 0 ) ) );
      return result;
    }
  },
  data: () => ({
    tabIndex: 0,
    mtrColumns: [],
    reportMtrColumns: [],
    filter: "",
    reportMtrFilter: "",
    decimalMask: currencyMask,
    mtrFilterData: initialMtrFilterData(),
    reportFilterData: initialReportFilterData(),
    reportMtrFilterData: {
      mnemonicCode: "",
      nomenclature: "",
    },
    clearedReportMtrFilterData: {
      mnemonicCode: "",
      nomenclature: "",
    },
    refusePositionList: [],
    mtrRejectComment: "",
    pageLoad: false,
    rejectComment: "",
    approveComment: "",
    mtrFields: [
      {
        key: "select",
        sortable: false,
        label: "",
        full: "",
        openDefault: true,
      },
      {
        key: "status",
        sortable: true,
        label: "С",
        full: "Состояние",
        openDefault: true,
      },
      {
        key: "mnemocode",
        sortable: true,
        label: "Мнемокод",
        full: "Мнемокод",
        openDefault: true,
      },
      {
        key: "nomenclature_name",
        nesting: "nomenclature_name",
        sortable: true,
        label: "Номенклатура",
        full: "Номенклатура",
        openDefault: true,
      },
      {
        key: "count",
        sortable: true,
        label: "Кол-во",
        full: "Количество",
        openDefault: true,
      },
      {
        key: "unit",
        sortable: true,
        label: "Ед. изм.",
        full: "Единица измерения",
        openDefault: true,
      },
      {
        key: "price_without_vat",
        sortable: true,
        label: "Цена ₽ (без НДС)",
        full: "Цена ₽ (без НДС)",
        openDefault: true,
      },
      {
        key: "amount_without_vat",
        sortable: true,
        label: "Сумма ₽ (без НДС)",
        full: "Сумма ₽ (без НДС)",
        openDefault: true,
      },
      {
        key: "delivery_plan_time",
        sortable: true,
        label: "Срок поставки по плану",
        full: "Срок поставки по плану",
        openDefault: true,
      },
      {
        key: "delivery_time",
        sortable: true,
        label: "Срок поставки по заказу",
        full: "Срок поставки по заказу",
        openDefault: true,
      },
      {
        key: "delivery_address",
        sortable: true,
        label: "Адрес поставки",
        full: "Адрес поставки",
        openDefault: true,
      },
      {
        key: "comments",
        sortable: false,
        label: "Комм",
        full: "Комментарий поставщика",
        openDefault: true,
      },
    ],
    reportPayFactsFields: [
      {
        key: "payment_register_date",
        sortable: true,
        label: "Дата",
        full: "Дата",
      },
      {
        key: "payment_register_amount_payment",
        sortable: true,
        label: "Сумма",
        full: "Сумма",
      },
    ],
    reportShipmentFactsFields: [
      {
        key: "consignment_date",
        sortable: true,
        label: "Дата",
        full: "Дата",
      },
      {
        key: "consignment_position_amount_with_vat",
        sortable: true,
        label: "Сумма",
        full: "Сумма",
      },
    ],
    reportMtrFields: [
      {
        key: "nomenclature_name",
        nesting: "nomenclature_name",
        sortable: true,
        label: "Номенклатура",
        full: "Номенклатура",
        openDefault: true,
      },
      {
        key: "mnemocode",
        sortable: true,
        label: "Мнемокод",
        full: "Мнемокод",
        openDefault: true,
      },
      {
        key: "unit",
        sortable: true,
        label: "Ед. изм.",
        full: "Единицы измерения",
        openDefault: true,
      },
      {
        key: "delivery_plan_count",
        sortable: true,
        label: "План поставки",
        full: "План поставки",
        openDefault: true,
      },
      {
        key: "delivery_fact_count",
        sortable: true,
        label: "Факт поставки",
        full: "Факт поставки",
        openDefault: true,
      },
      {
        key: "delivery_time",
        sortable: true,
        label: "Срок поставки по заказу",
        full: "Срок поставки по заказу",
        openDefault: true,
      },
      {
        key: "delivery_fact_time",
        sortable: true,
        label: "Фактический срок поставки",
        full: "Фактический срок поставки",
        openDefault: true,
      },
      {
        key: "remainder",
        sortable: true,
        label: "Остаток (Количество)",
        full: "Остаток (Количество)",
        openDefault: true,
      },
      {
        key: "delivery_plan_time",
        sortable: true,
        label: "Срок поставки по плану",
        full: "Срок поставки по плану",
        openDefault: true,
      },
      {
        key: "amount_without_vat",
        sortable: true,
        label: "Сумма по заказу (Без НДС)",
        full: "Сумма по заказу (Без НДС)",
        openDefault: true,
      },
      {
        key: "fact_amount_without_vat",
        sortable: true,
        label: "Сумма факт",
        full: "Фактическая сумма поставки",
        openDefault: true,
      },
    ],
    mtrLoading: false,
    reportLoading: false,
    sortingMtrData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
    sortingReportData: {
      sort_field: "",
      sort_order: "",
      page: "",
      per_page: 25,
    },
  }),

  methods: {
    async loadPage() {
      this.pageLoad = false;
      try {
        await Promise.all([
          this.$store.dispatch("getOrder", this.orderId),
          this.$store.dispatch("getOrderPositions", { id: this.orderId }),
          this.$store.dispatch("getReport", { id: this.orderId }),
        ]);
        this.mtrColumns = this.mtrFields.map((item) => item.label);
        this.reportMtrFields.forEach((item) => {
          this.reportMtrColumns.push(item.label);
        });
        this.order.positions?.forEach((position) => {
          if (position.status === "Отклонено") {
            this.refusePositionList.push(position.id);
          }
        });
      } finally {
        this.pageLoad = true;
      }
    },
    сloseOrder() {
      this.$store
        .dispatch("closeOrder", this.orderId)
        .then(async () => {
          this.$bvModal.hide("close-order");
          this.$bvToast.toast("Запрос на закрытие заказа успешно отправлен", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          });

          this.pageLoad = false;
          await this.loadPage();
        })
        .catch((e) => {
          // const errorText =
          //   e.response?.data?.message ||
          //   "Что-то пошло не так, попробуйте позднее";
          const errorText = e.response?.data?.message;

          if (errorText) {
            this.$bvToast.toast(errorText, {
              toaster: "b-toaster-top-right",
              solid: true,
              appendToast: false,
              variant: "danger",
              noAutoHide: true,
            });
          }
        });
    },
    confirmCloseOrder() {
      this.$store
        .dispatch("confirmCloseOrder", this.orderId)
        .then(async () => {
          this.$bvModal.hide("close-order");
          this.$bvToast.toast("Заказ закрыт", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          });
          this.$router.push("/purchase-orders");
        })
        .catch(() => {
          this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    rejectOrder() {
      this.$store
        .dispatch("rejectOrder", {
          id: this.orderId,
          comment: this.rejectComment,
        })
        .then(() => {
          this.$bvToast.toast("Отказано", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
          this.$router.push("/purchase-orders");
        })
        .catch(() => {
          this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    approveOrder() {
      this.$store
        .dispatch("approveOrder", {
          id: this.orderId,
          comment: this.approveComment,
        })
        .then(() => {
          this.$bvToast.toast("Согласовано", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          });
          this.$router.push("/purchase-orders");
        })
        .catch(() => {
          this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },

    approveOrderFromContractor() {
      axios.post( `/orders/${ this.orderId }/under_consideration` )
        .then(() => {
          this.$bvToast.toast("Согласовано", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          });
          this.$router.push("/purchase-orders");
        })
        .catch(() => {
          this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },

    deleteOrder() {
      this.$store
        .dispatch("deleteOrder", this.orderId)
        .then(() => {
          this.$bvToast.toast("Заказ успешно удален", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          });
          this.$router.push("/purchase-orders");
        })
        .catch(() => {
          this.$bvToast.toast("Что-то пошло не так, попробуйте позднее", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    mtrReject() {
      this.$store
        .dispatch("mtrReject", {
          id: this.orderId,
          rejected_position_ids: this.refusePositionList,
          comment: this.mtrRejectComment,
        })
        .then(async () => {
          this.$bvToast.toast("Отказано в выбранных позициях", {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "success",
          });
          this.$bvModal.hide("mtr-reject");
          this.mtrRejectComment = "";
          this.pageLoad = false;
          await this.$store.dispatch("getOrder", this.orderId);
          this.pageLoad = true;
        })
        .catch((error) => {
          this.$bvToast.toast(error.response.data.message, {
            toaster: "b-toaster-top-right",
            solid: true,
            appendToast: false,
            variant: "danger",
          });
        });
    },
    async updatePositionsDataWithFilters(clearSort) {
      this.mtrLoading = true;
      if (clearSort) {
        this.sortingMtrData.sort_field = "";
        this.sortingMtrData.sort_order = "";
        this.sortingMtrData.page = "";
      }
      await this.$store.dispatch("getOrderPositions", {
        id: this.orderId,
        filters: {
          ...this.sortingMtrData,
          ...this.mtrFilterData,
        },
      });
      this.mtrLoading = false;
    },
    async updateReportDataWithFilters(clearSort) {
      this.reportLoading = true;
      if (clearSort) {
        this.sortingReportData.sort_field = "";
        this.sortingReportData.sort_order = "";
        this.sortingReportData.page = "";
      }
      await this.$store.dispatch("getReport", {
        id: this.orderId,
        filters: {
          ...this.sortingReportData,
          ...this.reportFilterData,
        },
      });
      this.reportLoading = false;
    },
    clearMtrFilterData() {
      this.mtrFilterData = initialMtrFilterData();
      this.updatePositionsDataWithFilters(true);
    },
    clearReportFilterData() {
      this.reportFilterData = initialReportFilterData();
      this.updateReportDataWithFilters(true);
    },
    mtrSorting(event) {
      this.sortingMtrData.sort_field = event.sortBy;
      this.sortingMtrData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updatePositionsDataWithFilters();
    },
    reportSorting(event) {
      this.sortingReportData.sort_field = event.sortBy;
      this.sortingReportData.sort_order = event.sortDesc ? "desc" : "asc";
      this.updateReportDataWithFilters();
    },
    mtrPaginationChange(page) {
      this.sortingMtrData.page = page;
      this.updatePositionsDataWithFilters();
    },
    reportPaginationChange(page) {
      this.sortingReportData.page = page;
      this.updateReportDataWithFilters();
    },
    mtrPerPageChange(perPage) {
      this.sortingMtrData.page = "";
      this.sortingMtrData.per_page = perPage;
      this.updatePositionsDataWithFilters();
    },
    reportPerPageChange(perPage) {
      this.sortingReportData.page = "";
      this.sortingReportData.per_page = perPage;
      this.updateReportDataWithFilters();
    },
  },
  async mounted() {
    await this.loadPage();
    this.$store.dispatch("checkNotification", {
      entity: "orders",
      id: this.orderId,
    });
  },
};
</script>

<style scoped>
</style>
